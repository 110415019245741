/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "dark-mode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dark-mode",
    "aria-label": "dark mode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dark Mode"), "\n", React.createElement(_components.p, null, "Add support for dark mode but also any number of other color modes."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "getting-started",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-started",
    "aria-label": "getting started permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting started"), "\n", React.createElement(_components.h3, {
    id: "defining-colors",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#defining-colors",
    "aria-label": "defining colors permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Defining colors"), "\n", React.createElement(_components.p, null, "To enable color modes in your application, the first thing to do is to make your theme color mode compatible."), "\n", React.createElement(_components.p, null, "In the ", React.createElement(_components.code, null, "theme.colors"), " object, add a nested ", React.createElement(_components.code, null, "modes"), " object that will contain keys for optional color modes. The name for the base color mode is ", React.createElement(_components.code, null, "default"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const theme = {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      },\n    },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "The colors defined at the root of the ", React.createElement(_components.code, null, "colors"), " object will be accessible as ", React.createElement(_components.code, null, "default"), ". All colors found in colors.modes will be referenced by their key. The above example will have two modes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "default")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "dark")), "\n"), "\n", React.createElement(_components.h3, {
    id: "add-colormodeprovider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#add-colormodeprovider",
    "aria-label": "add colormodeprovider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Add ColorModeProvider"), "\n", React.createElement(_components.p, null, "After your theme and color modes are ready, you have to add a ", React.createElement(_components.code, null, "ColorModeProvider"), ", just after your ", React.createElement(_components.code, null, "ThemeProvider"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport { ThemeProvider, ColorModeProvider } from '@xstyled/styled-components'\nimport App from './App'\n\nconst theme = {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      },\n    },\n  },\n}\n\nfunction Root() {\n  return (\n    <ThemeProvider theme={theme}>\n      <ColorModeProvider>\n        <App />\n      </ColorModeProvider>\n    </ThemeProvider>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "Now your app is ready! It will automatically uses dark mode according to user system preferences."), "\n", React.createElement(_components.h2, {
    id: "live-example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#live-example",
    "aria-label": "live example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Live Example"), "\n", React.createElement("iframe", {
    src: "https://codesandbox.io/embed/musing-brown-f0qq7?fontsize=14",
    title: "xstyled-dark-mode",
    allow: "geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb",
    style: {
      width: '100%',
      height: '500px',
      border: 0,
      borderRadius: '4px',
      overflow: 'hidden'
    },
    sandbox: "allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
  }), "\n", React.createElement(_components.h2, {
    id: "basics",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basics",
    "aria-label": "basics permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basics"), "\n", React.createElement(_components.h3, {
    id: "change-color-mode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#change-color-mode",
    "aria-label": "change color mode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Change color mode"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "useColorMode"), " hook in your application to change the color mode. This value will be stored in ", React.createElement(_components.code, null, "localStorage"), " and used whenever the page is loaded."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport { useColorMode } from '@xstyled/styled-components'\n\nfunction Example(props) {\n  const [colorMode, setColorMode] = useColorMode()\n  return (\n    <header>\n      <button\n        onClick={(e) => {\n          setColorMode(colorMode === 'default' ? 'dark' : 'default')\n        }}\n      >\n        Toggle {colorMode === 'default' ? 'Dark' : 'Light'}\n      </button>\n    </header>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "gatsby",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gatsby",
    "aria-label": "gatsby permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gatsby"), "\n", React.createElement(_components.p, null, "To use it with ", React.createElement(_components.a, {
    href: "https://gatsbyjs.org"
  }, "Gatsby"), ", add the following code to ", React.createElement(_components.code, null, "gatsby-ssr.js"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport { getColorModeInitScriptElement } from '@xstyled/styled-components'\n\nexport function onRenderBody({ setPreBodyComponents }) {\n  setPreBodyComponents([getColorModeInitScriptElement()])\n}\n")), "\n", React.createElement(_components.h3, {
    id: "next",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#next",
    "aria-label": "next permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Next"), "\n", React.createElement(_components.p, null, "To use it with ", React.createElement(_components.a, {
    href: "https://nextjs.org/"
  }, "Next"), ", create a ", React.createElement(_components.code, null, "_document.js"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport Document, { Html, Head, Main, NextScript } from 'next/document'\nimport { getColorModeInitScriptElement } from '@xstyled/styled-components'\n\nexport default class MyDocument extends Document {\n  static async getInitialProps(ctx) {\n    const initialProps = await Document.getInitialProps(ctx)\n    return initialProps\n  }\n\n  render() {\n    return (\n      <Html>\n        <Head />\n        <body>\n          {getColorModeInitScriptElement()}\n          <Main />\n          <NextScript />\n        </body>\n      </Html>\n    )\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "server-side-rendering",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#server-side-rendering",
    "aria-label": "server side rendering permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Server Side Rendering"), "\n", React.createElement(_components.p, null, "For SSR, you have to include in the ", React.createElement(_components.code, null, "<head>"), " or at the top of the body the initialization script."), "\n", React.createElement(_components.p, null, "You can use ", React.createElement(_components.code, null, "getColorModeInitScriptElement"), " to get it as a React element or ", React.createElement(_components.code, null, "getColorModeInitScriptTag"), " to get it as a plain string."), "\n", React.createElement(_components.h2, {
    id: "advanced",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced",
    "aria-label": "advanced permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced"), "\n", React.createElement(_components.p, null, "There are a few advanced usages of color modes when you need more flexibility. For most use cases, you won't need to reference this section."), "\n", React.createElement(_components.h3, {
    id: "use-another-default-color-mode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-another-default-color-mode",
    "aria-label": "use another default color mode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use another default color mode"), "\n", React.createElement(_components.p, null, "The colors you define at the root level, outside of ", React.createElement(_components.code, null, "theme.colors.modes"), " is named ", React.createElement(_components.code, null, "default"), ". This is the color mode used by default. Use ", React.createElement(_components.code, null, "defaultColorModeName"), " to start with another color mode:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  defaultColorModeName: 'dark',\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.p, null, "At the first visit, the user will use the ", React.createElement(_components.code, null, "dark"), " color mode."), "\n", React.createElement(_components.h3, {
    id: "renaming-the-default-color-mode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#renaming-the-default-color-mode",
    "aria-label": "renaming the default color mode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Renaming the default color mode"), "\n", React.createElement(_components.p, null, "The colors you define at the root level, outside of ", React.createElement(_components.code, null, "theme.colors.modes"), " is named ", React.createElement(_components.code, null, "default"), ". If you'd like to customize it you can do so by setting ", React.createElement(_components.code, null, "initialColorModeName"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  initialColorModeName: 'light',\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.p, null, "At the first visit, the user will use the ", React.createElement(_components.code, null, "light"), " color mode that is implicitly the default one."), "\n", React.createElement(_components.h3, {
    id: "disable-prefers-color-scheme",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#disable-prefers-color-scheme",
    "aria-label": "disable prefers color scheme permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Disable ", React.createElement(_components.code, null, "prefers-color-scheme")), "\n", React.createElement(_components.p, null, "By default, ", React.createElement(_components.code, null, "dark"), " and ", React.createElement(_components.code, null, "light"), " mode are automatically activated if ", React.createElement(_components.code, null, "@media (prefers-color-scheme: dark)"), " matches. It means it automatically follows user system preference."), "\n", React.createElement(_components.p, null, "To turn off this behaviour, add ", React.createElement(_components.code, null, "useColorSchemeMediaQuery: false"), " to your theme:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  useColorSchemeMediaQuery: false,\n  colors: {\n    text: '#000',\n    background: '#fff',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "turn-off-custom-properties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#turn-off-custom-properties",
    "aria-label": "turn off custom properties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Turn off custom properties"), "\n", React.createElement(_components.p, null, "The default implementation of xstyled color modes uses ", React.createElement(_components.a, {
    href: "https://caniuse.com/#feat=css-variables"
  }, "CSS custom properties"), ". If you're supporting browsers that don't support custom properties you can turn off this setting."), "\n", React.createElement(_components.p, null, "Not using CSS custom properties cause the colors to flash on initial page load if you use SSR or static generation (", React.createElement(_components.a, {
    href: "https://nextjs.org/"
  }, "Next"), " or ", React.createElement(_components.a, {
    href: "https://gatsbyjs.org"
  }, "Gatsby"), ")."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// example theme colors\n{\n  useCustomProperties: false,\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      }\n    }\n  }\n}\n")), "\n", React.createElement(_components.h3, {
    id: "use-another-target",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-another-target",
    "aria-label": "use another target permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use another target"), "\n", React.createElement(_components.p, null, "To make color mode works, a class is added to ", React.createElement(_components.code, null, "document.body"), ". Sometimes you may want to specify another target, more specific. To do so, add ", React.createElement(_components.code, null, "target"), " and ", React.createElement(_components.code, null, "targetSelector"), " to ", React.createElement(_components.code, null, "ColorModeProvider"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import React from 'react'\nimport { ThemeProvider, ColorModeProvider } from '@xstyled/styled-components'\nimport App from './App'\n\nconst target = document.getElementById('small-react-app')\n\nconst theme = {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#07c',\n    modes: {\n      dark: {\n        text: '#fff',\n        background: '#000',\n        primary: '#0cf',\n      },\n    },\n  },\n}\n\nfunction Root() {\n  return (\n    <ThemeProvider theme={theme}>\n      <ColorModeProvider target={target} targetSelector=\"#small-react-app\">\n        <App />\n      </ColorModeProvider>\n    </ThemeProvider>\n  )\n}\n\nrender(<Root />, target)\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "getColorModeInitScriptElement"), " & ", React.createElement(_components.code, null, "getColorModeInitScriptTag"), " also accept a ", React.createElement(_components.code, null, "target"), " option to configure the script. Example: ", React.createElement(_components.code, null, "getColorModeInitScriptTag({ target: 'document.getElementById(\"small-react-app\")' })")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
